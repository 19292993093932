import * as React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import SvgInline from '../SvgInline/SvgInline';
import { Heading5 } from '../Heading/Heading';
import './_modal.scss';
import { COLOR } from '../../utils/constants';
import { Text } from '../Text';

const component = 'modal';

if (typeof document !== 'undefined') {
  ReactModal.setAppElement(
    document.getElementById('___gatsby') || document.getElementById('root')
  );
}
const Modal = props => {
  const {
    className,
    isOpen,
    onRequestClose,
    title,
    parentSelector,
    content,
    customContent
  } = props;

  return (
    <ReactModal
      closeTimeoutMS={200}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      portalClassName={`${component}__portal`}
      overlayClassName={`${component}__overlay`}
      className={classnames(`${component}__panel`, className)}
      parentSelector={parentSelector}
    >
      <React.Fragment>
        <div className={`${component}__header`}>
          <Heading5
            marginBottom={0}
            color={COLOR.BLACK}
            className={`${component}__title`}
          >
            {title}
          </Heading5>
          <button
            type="button"
            className={`${component}__close-button`}
            onClick={onRequestClose}
          >
            <SvgInline
              ariaLabel="Close"
              name="cross"
              style={{
                width: 25,
                height: 25
              }}
              strokeBlueBright
            />
          </button>
        </div>
        <div className={`${component}__content`}>
          {customContent}
          <Text>{content}</Text>
        </div>
      </React.Fragment>
    </ReactModal>
  );
};

export default Modal;
